<template>
  <div class="test">
    <div class="test-type">
      <div class="pic">
        <img :src="getProductPic(product.productCode)">
      </div>
      <div class="test-type__title">
        <p class="title">
          {{ product.productName }}
        </p>
        <p class="text">
          {{ product.description }}
        </p>
      </div>
    </div>
    <div class="test-index text-center">
      第<span class="primary-c">{{ productItemIndex +1 }}</span>/{{ productItemsLen }}题目
    </div>
    <div class="test-title">
      <div class="item">
        <div class="item-label">
          测评项目{{ productItemIndex+1 }}：
        </div>
        <div class="item-value">
          {{ productItem.productItemName }}
        </div>
      </div>
      <div class="item">
        <div class="item-label">
          操作方法：
        </div>
        <div class="item-value">
          {{ productItem.operateMethod }}
        </div>
      </div>
      <div
        v-if="productItem.noteItem"
        class="item"
      >
        <div class="item-label">
          注意事项：
        </div>
        <div class="item-value">
          {{ productItem.noteItem }}
        </div>
      </div>
    </div>
    <!-- <p class="test-text question">
      <img src="../images/finger.svg">
      {{ option.itemOptionsChar }}：{{ option.itemOptionsName }}
    </p> -->
    <div class="test-answer">
      <div class="title">
        宝宝能完成以下哪个等级？
      </div>
      <div
        v-for="(item, index) in (productItem.productItemOptionsList||[])"
        :key="index"
        class="test-answer__item"
      >
        <div
          class="box"
          @click="select(item)"
        >
          <div class="left">
            <span
              class="circle"
              :class="{select: item.itemOptionsChar === productItem.itemOptionsChar}"
            >
              <i class="el-icon-check" />
            </span>
            <span>{{ item.itemOptionsChar }}</span>
          </div>
          <div class="center">
            {{ item.itemOptionsName }}
          </div>
        </div>
        <div
          class="right"
          @click.stop="playerHandler(item)"
        >
          <!-- itemOptionsVideoUrl -->
          <img
            class="player"
            src="../images/player.svg"
          >查看{{ item.itemOptionsChar }}示范视频
        </div>
      </div>
    </div>
    <Player
      ref="player"
      :option="curOption"
    />
  </div>
</template>

<script>
export default {
  components: {
    Player: () => import('./player.vue')
  },
  props: {
    // 产品
    product: {
      type: Object,
      default: () => ({})
    },
    // 选项
    productItem: {
      type: Object,
      default: () => ({})
    },
    productItemIndex: {
      type: Number,
      default: 0
    },
    productItemsLen: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      curOption: {}
    };
  },
  mounted() {
    this.keyBoard();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyEvent);
  },
  methods: {
    getProductPic(productCode) {
      let pic;
      try {
        // eslint-disable-next-line
        pic = require(`../images/product/${productCode}.jpg`);
      } catch (error) {
        // eslint-disable-next-line
        pic = require('../images/pic-hearing.png');
      }
      return pic;
    },
    select(productItem) {
      this.$emit('select', productItem);
    },
    playerHandler(option) {
      if (this.$refs.player) {
        this.curOption = option;
        this.$refs.player.open();
      }
    },
    keyBoard() {
      document.addEventListener('keydown', this.keyEvent);
    },
    keyEvent() {
      const key = window.event.keyCode;
      const selectItem = this.productItem.productItemOptionsList[key - 65];
      if (selectItem) {
        this.select(selectItem);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.test{
  .border{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    overflow: hidden;
  }
  .question{
    display: flex;
    align-items: flex-start;
    img{
      height: 40px;
      margin-top: -10px;
    }
  }
  &-type{
    .border;

    display: flex;
    margin-bottom: 16px;
    &__title{
      flex: 1;
      margin-left: 17px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 20px;
    }
    .title{
      font-weight: 500;
      font-size: 16px;
      color: #333;
      margin-bottom: 0;
    }
    .text{
      font-size: 14px;
      color: #888;
      line-height: 1.25;
    }
  }
  .pic {
    width: 102px;
    img{
      height: 100%;
      width: 102px;
    }
  }
  &-index{
    margin-bottom: 16px;
    font-size: 14px;
    color: #BDBDBD;
  }
  &-title{
    .border;

    padding: 15px;
    margin-bottom: 16px;
    // font-weight: 500;
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    .item{
      font-size: 14px;
      line-height: 24px;
      display: flex;
      &-label{
        font-weight: 600;
        width: 90px;
      }
      &-value{
        flex: 1;
      }
    }
  }
  &-text{
    font-size: 16px;
  }
  &-video{
    height: 218px;
    line-height: 218px;
    margin-bottom: 16px;
  }
  &-answer{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 0 16px;
    margin-bottom: 16px;
    .title{
      font-weight: 500;
      font-size: 14px;
      margin: 16px 0;
    }
    &__item{
      .border;

      margin-bottom: 16px;
      padding: 8px 16px;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      .box{
        flex: 1;
        display: flex;
      }
      .left{
        width: 80px;
        font-weight: 500;
      }
      .center{
        flex: 1;
        line-height: 1.5;
      }
      .right{
        margin-left: 15px;
        color: #5FB4BF;
        .player{
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
    .circle{
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      border: 1px solid #BDBDBD;
      border-radius: 100%;
      display: inline-block;
      margin-right: 16px;
      color: white;
      &.select{
        background: #85C6CE;
        border-color: #85C6CE;
      }
    }
  }
}
</style>
